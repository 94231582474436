import axios from 'axios'
import { BACKEND_URL } from '../config';

const _axios = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' }
})

// export default interface Device {
//   id: string,
//   x: number,
//   y: number,
//   sequence: number
// }

class DeviceService {
  getDevices() {
    return _axios.get(`api/devices`);
  }

  updateDevice(device) {
    return axios.post(`api/devices/`, { device });
  }

  clear() {
    return axios.post(`api/devices/clear`);
  }
}

export const deviceService = new DeviceService();
