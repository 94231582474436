const STROKE = 1
export const defaultOptions = {
    radius: 1.3,
    fillColor: "#7da2ff",
    strokeColor: "#6f81ff",
    pathWidth: 0.3,
    pathColor: "red",
};

export const MAP_OPTIONS = {
    SRC: {
        radius: 4 * STROKE,
        fillColor: "#3dfa1f",
        strokeWidth: 0,
        strokeColor: "#3dfa1f",
        pathWidth: 2 * STROKE,
        pathColor: "#3dfa1f",
        tagColor: "#3dfa1f",
    },
    MAT: {
        radius: 4 * STROKE,
        fillColor: "#ff71f9",
        strokeWidth: 0,
        strokeColor: "#ff71f9",
        pathWidth: 2 * STROKE,
        pathColor: "#ff71f9",
        tagColor: "#ff71f9",
    },
    KAL: {
        radius: 10 * STROKE,
        fillColor: "#e9a60f",
        strokeWidth: 0,
        strokeColor: "#e9a60f",
        pathWidth: 2 * STROKE,
        pathColor: "#ff003b",
        tagColor: "#e9a60f",
    },
    ANG: {
        radius: 6 * STROKE,
        fillColor: "#7da2ff",
        strokeWidth: 0,
        strokeColor: "#6f81ff",
        pathWidth: 2 * STROKE,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
    },
    HOR: {
        radius: 10 * STROKE,
        fillColor: "none",
        strokeWidth: 2.6 * STROKE,
        strokeColor: "#8624fb",
        pathWidth: 2 * STROKE,
        pathColor: "#aa7de1",
        tagColor: "#9944ff",
    },
    PRX: {
        radius: 10 * STROKE,
        fillColor: "none",
        strokeWidth: 2.6 * STROKE,
        strokeColor: "#459a90",
        pathWidth: 2 * STROKE,
        pathColor: "#aa7de1",
        tagColor: "#459a90",
    },
    EMU: {
        radius: 10 * STROKE,
        fillColor: "none",
        strokeWidth: 2.6 * STROKE,
        strokeColor: "#cf9116",
        pathWidth: 2 * STROKE,
        pathColor: "#aa7de1",
        tagColor: "#cf9116",
    },
    CTE: {
        radius: 8 * STROKE,
        fillColor: "#479c99",
        strokeWidth: 2.6 * STROKE,
        strokeColor: "#479c99",
        pathWidth: 2 * STROKE,
        pathColor: "#f0542d33",
        tagColor: "#479c99",
    },
    RSSI: {
        radius: 5 * STROKE,
        fontSize: 0.15 * STROKE,
        fontColor: "#00bb84",
        fillColor: "#028487",
        fillOpacity: 0.1,
        strokeWidth: 0.005,
        strokeColor: "#028487",
        pathWidth: 2 * STROKE,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
        timeColor: "#20a0d8"
    },
    WLK: {
        radius: 20 * STROKE,
        fillColor: "none",
        strokeWidth: 4 * STROKE,
        strokeColor: "#479c99",
        pathWidth: 12 * STROKE,
        pathColor: "#f0542d33",
        tagColor: "#479c99",
    },
    DNA: {
        radius: 10 * STROKE,
        fillColor: "#7da2ff",
        strokeWidth: 2.6 * STROKE,
        strokeColor: "#6f81ff",
        pathWidth: 2 * STROKE,
        pathColor: "#73e0ff",
        tagColor: "#7da2ff",
    },
    LOCATOR: {
        radius: 0.2,
        fontSize: 0.2 * STROKE,
        fontColor: "#83831c",
        fillColor: "#e19a61",
        inactiveFillColor: "#debfa6",
        activeColor: "#00bb84",
        inactiveColor: "#bcbcbc",
        strokeWidth: 0.1,
        strokeColor: "#242424",
        fillOpacity: "60%",
        tagColor: '#e19a61',
    },
    ACCESS_POINT: {
        radius: 0.2,
        fontSize: 0.2 * STROKE,
        fontColor: "#933c93",
        fillColor: "#cb95cb",
        inactiveFillColor: "#d6c7d6",
        activeColor: "#00bb84",
        inactiveColor: "#bcbcbc",
        strokeWidth: 0.1,
        strokeColor: "#242424",
        fillOpacity: "60%",
        tagColor: '#cb95cb',
    },
    GEOFENCE: {
        fontSize: 12 * STROKE,
        fillColors: ["#97bfdb", "#eac4d5", "#e8eac4", "#addbad", "#c4eae5", "#aa97db"],
        fillOpacity: "10%",
        strokeDashArray: 2 * STROKE,
        strokeWidth: 1.4 * STROKE,
        strokeColors: ["#97bfdb", "#eac4d5", "#e8eac4", "#addbad", "#c4eae5", "#aa97db"],
    },
}
