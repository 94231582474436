import Vue from 'vue'
import Vuex from 'vuex'

import { deviceService } from "../services/DeviceService";
import { buildingService } from "../services/BuildingService";

Vue.use(Vuex)

const find = (devices, device) => {
  var result = devices.find((d) => d.id == device.id && d.tag == device.tag)
  return result
}

const indexOf = (devices, device) => {
  var index = devices.findIndex((d) => d.id == device.id && d.tag == device.tag)
  return index
}

export default new Vuex.Store({
  state: {
    consoleLog: false,
    enableDeviceTrace: false,
    deviceThroughput: {},
    devices: [],
    buildings: []
  },
  actions: {
    setDeviceThroughput({ commit }, data) {
      commit('setDeviceThroughput', data)
    },

    incrementDeviceThroughput({ commit }, data) {
      commit('incrementDeviceThroughput', data)
    },

    clearDevices({ commit }) {
      commit('setDevices', [])
    },

    loadBuildings({ commit }) {
      commit('setBuildings', [])

      return buildingService.getBuildings().then((res) => {
        this.buildings = res.data;
        commit('setBuildings', res.data)
      });
    },

    reloadBuildingsMaps({ commit }) {
      return buildingService.reloadMaps()
        .then((res) => {
          console.log("reloadBuildingsMaps", res);
        })
        .catch(errors => {
          console.error("reloadBuildingsMaps", errors);
        });
    },

    loadDevices({ commit }) {
      commit('setDevices', [])

      // deviceService.getDevices().then((res) => {
      //   this.devices = res.data;
      //   commit('setDevices', res.data)
      // });
    },

    findDevice({ state }, device) {
      return find(state.devices, device)
    },

    findDeviceIndex({ state }, device) {
      return indexOf(state.devices, device)
    },

    addDevice({ state, commit }, device) {
      // device.sequence = 0;
      // device.latency = (new Date() - new Date(device.time)) / 1000.0;
      commit('updateDevice', device)
      // if (indexOf(state.devices, device) >= 0) {
      //   commit('updateDevice', device)
      // } else {
      //   commit('addDevice', device)
      // }
    },

    removeDevice({ commit }, device) {
      commit('removeDevice', device)
    },

    clearDeviceTrace({ commit }) {
      commit('clearDeviceTrace')
    },

    enableLogConsole({ commit }, value) {
      commit('enableLogConsole', value)
    }
  },
  mutations: {
    setDeviceThroughput(state, data) {
      var deviceId = data.deviceId;
      var value = data.val;
      if (state.deviceThroughput[deviceId] == undefined) {
        state.deviceThroughput[deviceId] = 0;
      }
      state.deviceThroughput[deviceId] = parseInt(value);
    },

    incrementDeviceThroughput(state, data) {
      var deviceId = data.deviceId;
      var increment = data.val;
      if (state.deviceThroughput[deviceId] == undefined) {
        state.deviceThroughput[deviceId] = 0;
      }
      state.deviceThroughput[deviceId] += parseInt(increment);
    },

    setBuildings(state, buildings) {
      state.buildings = buildings
    },

    setDevices(state, devices) {
      state.devices = devices
    },

    addDevice(state, device) {
      device.trace = []
      state.devices.push(device)
    },

    removeDevice(state, deviceId) {
      var index = indexOf(state.devices.deviceId)
      if (index >= 0) {
        state.devices.splice(index, 1)
      }
    },

    updateDevice(state, device) {
      var existingDevice = find(state.devices, device)
      if (existingDevice === undefined) {
        existingDevice = device;
        existingDevice.trace = []
        existingDevice.sequence = 0
        state.devices.push(existingDevice)
      }

      existingDevice.x = device.x
      existingDevice.y = device.y
      existingDevice.tag = device.tag
      existingDevice.floorId = device.floorId
      existingDevice.time = device.time
      existingDevice.locatorTime = device.locatorTime

      existingDevice.sequence = existingDevice.sequence + 1;
      existingDevice.latency = (new Date() - new Date(device.time)) / 1000.0;


      if (existingDevice.trace === undefined) existingDevice.trace = []

      existingDevice.trace.push({ x: device.x, y: device.y })

      const MAXLEN = 20;
      var len = existingDevice.trace.length;
      if (len > MAXLEN)
        existingDevice.trace.splice(0, len - MAXLEN)

    },

    clearDeviceTrace(state) {
      state.devices = this.devices.map((d) => {
        d.trace = [];
        return d;
      });
    },

    enableLogConsole(state, value) {
      console.log("Set 'consoleLog'", value)
      state.consoleLog = value;
    }
  }
})