import axios from 'axios'
import { BACKEND_URL } from '../config';

const _axios = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' }
})

class BuildingService {
  getBuildings() {
    return _axios.get(`api/buildings`);
  }

  getLocators(buildingId) {
    return _axios.get(`api/buildings/locators`, { params: { buildingId: buildingId } });
  
  }
  
  getAccessPoints(buildingId) {
    return _axios.get(`api/buildings/access-points`, { params: { buildingId: buildingId } });
  }

  reloadMaps() {
    return _axios.post(`api/floors/reload-maps`);
  }

  clearCache() {
    return _axios.post(`api/buildings/clear-cache`);
  }
}

export const buildingService = new BuildingService();
