<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",

  created() {
    console.log("Application's root component created.")
  },
};
</script>
