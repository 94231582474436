export const BUILDINGS = [
    {
        name: "Borda Office",
        enabled: true,
        branchId: 23,
        channel: "borda",
        floors: [
            {
                id: 12851,
                name: "5",
                index: 5,
                zFactor: 3,
                viewBox: "239 246 795 368",
                svgFileName: "map-borda-12851.svg"
            }
        ]
    },
    {
        name: "Izmir Office",
        enabled: true,
        branchId: 15,
        channel: "izmir",
        floors: [
            {
                id: 12712,
                name: "1",
                index: 1,
                zFactor: 3,
                viewBox: "266 87 656 506",
                svgFileName: "map-izmir-12712.svg"
            }
        ]
    },
    {
        name: "Taksim",
        enabled: true,
        branchId: 27,
        channel: "taksim",
        floors: [
            {
                id: 22903,
                name: "B2",
                index: -2,
                zFactor: 0.4,
                viewBox: "452 423 116 77",
                svgFileName: "map-taksim-22903.svg"
            }
        ]
    },
    {
        name: "Liv Vadi",
        enabled: true,
        branchId: 29,
        channel: "livvadi",
        floors: [
            {
                id: 23082,
                name: "B1",
                index: -1,
                zFactor: 0.52,
                viewBox: "469 302 136 164",
                svgFileName: "map-livvadi-23082.svg"
            },            
            {
                id: 23083,
                name: "5",
                index: 5,
                zFactor: 0.27,
                viewBox: "491 346 70 46",
                svgFileName: "map-livvadi-23083.svg"
            },            
            {
                id: 23084,
                name: "7",
                index: 7,
                zFactor: 0.27,
                viewBox: "490 375 71 47",
                svgFileName: "map-livvadi-23084.svg"
            },
            {
                id: 23085,
                name: "8",
                index: 8,
                zFactor: 0.27,
                viewBox: "435 381 71 47",
                svgFileName: "map-livvadi-23085.svg"
            }
        ]
    },
    {
        name: "HIMSS",
        enabled: true,
        branchId: 31,
        channel: "himss",
        floors: [
            {
                id: 23074,
                name: "0",
                index: 0,
                zFactor: 0.03,
                viewBox: "531 371 7 6",
                svgFileName: "map-himss-23074.svg"
            }
        ]
    },
    {
        name: "DNA Spaces",
        enabled: true,
        branchId: 1,
        channel: "dna",
        floors: [
            {
                id: 11,
                name: "5",
                index: 5,
                zFactor: 3,
                viewBox: "239 246 795 368",
                svgFileName: "map-borda-11.svg"
            }
        ]
    },
]