const Layout = () => import("@/layout/Layout");
const Dashboard = () => import("@/components/Dashboard");
const IndoorMap = () => import("@/components/IndoorMap");
const DeviceBoard = () => import("@/components/DeviceBoard");
const PacketsBoard = () => import("@/components/PacketsBoard");
const LocatorsBoard = () => import("@/components/LocatorsBoard");
const NotFound = () => import("@/components/NotFound");
const Building = () => import("@/pages/Building");

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "map/:deviceId",
        name: "map",
        component: IndoorMap
      },
      {
        path: "branch/:branchId/building/:buildingId",
        name: "building",
        component: Building,
      },
      {
        path: "map/:buildingId/floor/:floorId/:deviceId",
        name: "indoor-map",
        component: IndoorMap,
      },
      {
        path: "device/:buildingId/floor/:floorId/:deviceId",
        name: "device-board",
        component: DeviceBoard,
      },
      {
        path: "locators/:buildingId/floor/:floorId/:deviceId",
        name: "locators-board",
        component: LocatorsBoard,
      },
      {
        path: "packets/:buildingId/floor/:floorId/:deviceId",
        name: "packets-board",
        component: PacketsBoard,
      },
    ],
  },
  { path: "*", component: NotFound }
];

export default routes;
