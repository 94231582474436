export const NODE_ENV = process.env.NODE_ENV;
export const BACKEND_URL = process.env.VUE_APP_BACKEND_BACKEND_URL;

export { BUILDINGS } from "./config/buildings.js";
export { MAP_OPTIONS } from "./config/map-options.js";

console.log("NODE_ENV", NODE_ENV)
console.log("BACKEND_URL", BACKEND_URL)


