import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index'
import loading from 'vuejs-loading-screen'
import VueDragscroll from 'vue-dragscroll' 

import 'bootstrap'
import {Tooltip} from 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import './scss/style.scss'
import store from './store'
import { BACKEND_URL } from './config'

Vue.config.productionTip = false
axios.defaults.baseURL = BACKEND_URL

Vue.directive('tooltip', {
  inserted: function (el) {
    new Tooltip(el)
  }
})
Vue.use(VueDragscroll)
Vue.use(loading, {
  bg: '#0a0a0aa1',
  slot: `
    <div class="px-5 py-3">
      <div class="d-flex">
        <h6 class="m-0 align-self-center text-3xl text-white"><i class="fas fa-spinner fa-spin"></i> Loading, please wait...</h6>
        <div class="ms-3 text-white spinner-border spinner-border-md" role="status">
          <span class="visually-hidden">...</span>
        </div>
      </div>
      </div>
    </div>
  `
});

function initializeApp() {
  console.log("Starting up the application.");
  return new Promise((resolve, reject) => {
    store.dispatch("loadBuildings")
      .then(() => console.log("Application's data has been initialized..."))
      .then(() => resolve())
      .catch(() => reject());
  });
}

initializeApp().then(() => {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
})

